import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId, VerticalContent } from "../components/Navigation";
import Accordion from "../components/Accordion";
import CommonButton from "../components/Button";
import Map from "../svgComponents/Map";
import { COLORS } from "../utils/consts";
import { getIsVertical } from "../utils/getIsVertical";

import "../styles/mobila-paliativa-aprupe-majas.css";

export default function MobilePalliativeCare() {
	const { t } = useTranslation();
	const isVertical = getIsVertical();
	const [isExpanded, setIsExpanded] = React.useState(isVertical);

	return (
		<Layout pageId={PageId.MobilePalliativeCare}>
			<Seo
				title={t("seoTitle")}
				description={t("seoDescription")}
			/>
			<div className="contentBlock topTextSection">
				<h1>
					<Trans i18nKey="topText" components={{ conditionalBreak1: isVertical ? <></> : <br />, conditionalBreak2: isVertical ? <br /> : <></>, break: <br />}} />
				</h1>
			</div>
			<div className="contentBlock noPadding" style={{ display: "flex", flexDirection: isVertical ? "column" : "row" }}>
				<div style={{ width: isVertical ? "" : "130%", padding: isVertical ? "3rem" : "5rem 9rem 5rem 5rem", order: isVertical ? 2 : 1 }}>
					<p style={{ fontSize: isVertical ? "3rem" : "1.4rem", margin: isVertical? "2rem 0 6rem" : "3rem 0" }}>
						{t("whatsPalliativeCare")}
					</p>
					<p style={{ fontSize: isVertical ? "" : "1.4rem", margin: isVertical ? "3rem 0" : "2rem 0", fontFamily: "WT Monarch Nova, Source Serif, Untitled Sans, sans-serif" }}>
						{t("palliativeCareDescription")}
					</p>
					<p style={{ fontSize: isVertical ? "" : "1.4rem", margin: isVertical ? "3rem 0" : "2rem 0", fontFamily: "WT Monarch Nova, Source Serif, Untitled Sans, sans-serif" }}>
						{t("palliativeCareDescription2")}
					</p>
				</div>
				<div style={{ borderBottom: isVertical ? `1px solid ${COLORS.white}` : "", height: isVertical ? "50rem" : "", order: isVertical ? 1 : 2 }}>
					<StaticImage
						src="../images/mobila-paliativa-aprupe-majas/1.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt={t("seoTitle")}
						placeholder="blurred"
						loading="eager"
						style={{
							width: "100%",
							height: "100%",
						}}
					/>
				</div>
			</div>
			<div className="contentBlock" style={isVertical ? { padding: "3rem" } : {}}>
				<Accordion
					items={[
						{
							Title: <p style={{ textAlign: "left" }}>{t("medicalOffers")}</p>,
							Content: (
								<div className="accordionMenuContent">
									<p>{t("medicalOffer1")}</p>
									<p>{t("medicalOffer2")}</p>
									<p>{t("medicalOffer3")}</p>
									<p>{t("medicalOffer4")}</p>
									<p>{t("medicalOffer5")}</p>
								</div>
							),
						},
						{
							Title: <p style={{ textAlign: "left" }}>{t("otherOffers")}</p>,
							Content: (
								<div className="accordionMenuContent">
									<p>{t("otherOffer1")}</p>
									<p>{t("otherOffer2")}</p>
									<p>{t("otherOffer3")}</p>
									<p>{t("otherOffer4")}</p>
									<p>{t("otherOffer5")}</p>
									<p>{t("otherOffer6")}</p>
								</div>
							),
						},
					]}
				/>
			</div>
			<div className="contentBlock noPadding">
				<div className="imageWithExpandableSection">
					<StaticImage
						src="../images/mobila-paliativa-aprupe-majas/2.jpeg"
						quality={95}
						formats={["auto", "webp", "avif"]}
						alt={t("howToApply")}
						placeholder="blurred"
						imgStyle={{ margin: isVertical ? "" : "-1rem 0", height: isVertical ? "" : "calc(100% + 2rem)" }}
					/>
					<div className={`expandableSection ${isExpanded ? "expanded" : ""}`} onClick={() => { if (!isVertical) { setIsExpanded(true); }} }>
						<div className={`expandableSectionTitle ${isExpanded ? "" : "visible"}`}>
							<VerticalContent content={t("howToApply")} />
						</div>
						<div className={`expandableSectionContent ${isExpanded ? "visible" : ""}`}>
							<p style={{ fontFamily: "Untitled Sans, sans-serif", fontSize: isVertical ? "2.7rem" : "", marginBottom: isVertical ? "5rem" : "" }}>{t("howToApply")}</p>
							<p>{t("howToApply1")}</p>
							<p>{t("howToApply2")}</p>
							<p>{t("howToApply3")}</p>
							<p>{t("howToApply4")}</p>
							<a target="_blank" href="https://www.lm.gov.lv/lv/paliativas-aprupes-mobilas-komandas-pakalpojums-pacienta-dzivesvieta"><CommonButton style={{ borderColor: COLORS.lightGreen }}>{t("knowMore")}</CommonButton></a>
						</div>
					</div>
				</div>
			</div>
			<div className="contentBlock">
				<p className="contactUs">{t("contactUs")}</p>
				<Map />
			</div>
		</Layout>
	);
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["mobila-paliativa-aprupe-majas", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
